@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap");

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
}

.not-shown {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .hover-next-visible:hover + .not-shown {
    display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.iphone-fix {
  position: sticky;
  bottom: calc(env(safe-area-inset-bottom) + 9px);
  margin-bottom: 8px;
}
